import {STORAGE_URL} from "../../../constants/Endpoints";
import {getAxiosDataFetchRetryInstance} from "../../../utils/fetchData";
import {ReactComponent as DownloadButton} from "../../../assets/search/download-button.svg";
import {ReactComponent as PlayButton} from "../../../assets/search/play-button.svg";
import {ReactComponent as PauseButton} from "../../../assets/search/pause-button.svg";
import {useEffect, useRef, useState} from "react";


export default function SearchTableRow({audioRef, data}: Readonly<{ audioRef: any, data: any }>) {
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        audioRef.current.addEventListener("pause", () => {
            setIsPlaying(false);
        });
        audioRef.current.addEventListener("play", () => {
            if (audioRef.current.id === data.storageObjectReference) {
                setIsPlaying(true);
            } else {
                setIsPlaying(false);
            }
        });
        audioRef.current.addEventListener("ended", () => {
            setIsPlaying(false);
        });
        audioRef.current.addEventListener("error", () => {
            setIsPlaying(false);
        });
    }, []);


    function displayPlayPauseButton() {
        if (!audioRef.current.playing && isPlaying) {
            return (
                <PauseButton className={"h-5 w-5 cursor-pointer fill-neutral-800 hover:fill-neutral-600"}
                             onClick={() => {
                                 pauseAudio(audioRef);
                                 setIsPlaying(false);
                             }
                             }/>
            );
        } else {
            return (
                <PlayButton className={"h-5 w-5 cursor-pointer fill-neutral-800 hover:fill-neutral-600"}
                            onClick={() => {
                                playAudio(data, audioRef);
                                setIsPlaying(true);
                            }}/>
            );
        }
    }

    return (
        <tr className={"border-b-2 h-10"}>
            <td>{getStartTime(data.start)}</td>
            <td>{getTimeZone(data.start)}</td>
            <td>{getDuration(data.duration)}</td>
            <td>{data.clid}</td>
            <td>{data.to}</td>
            <td>{data.channel}</td>
            <td>
                <div
                    className={"bg-cyan-600 rounded pl-1 text-white text-xs font-normal uppercase leading-normal py-0.5"}>
                    {data.disposition}
                </div>
            </td>
            <td>
                <div className={"flex flex-row gap-2 ml-2"}>
                    {displayPlayPauseButton()}
                    <DownloadButton className={"h-5 w-5 cursor-pointer fill-neutral-800 hover:fill-neutral-600"}
                                    onClick={() => downloadAudioFile(data)}/>
                </div>
            </td>
        </tr>
    );


    function playAudio(data: any, audioRef: any) {
        const axiosDataFetchRetry = getAxiosDataFetchRetryInstance();
        let objectName = data.storageObjectReference

        // remove .wav from objectName and add .mp3
        objectName = objectName.substring(0, objectName.length - 4) + ".mp3";

        axiosDataFetchRetry.get(STORAGE_URL + "/" + objectName, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'audio/mp3',
            }
        }).then(async (response) => {
            let blob = new Blob([response.data], {type: 'audio/mp3'});
            let url = window.URL.createObjectURL(blob);
            let audio = new Audio();
            audio.crossOrigin = "anonymous";
            audio.src = url;

            // Set Audio source, Show player and play the audio
            audioRef.current.id = data.storageObjectReference;
            audioRef.current.src = url;
            audioRef.current.classList.remove("hidden");
            audioRef.current.play();
        }).catch((error) => {
            console.error(error);
        });
    }


    function getDuration(duration: number) {
        let hours = Math.floor(duration / 3600);
        let minutes = Math.floor((duration - (hours * 3600)) / 60);
        let seconds = duration - (hours * 3600) - (minutes * 60);

        let hoursString = hours < 10 ? "0" + hours.toString() : hours.toString();
        let minutesString = minutes < 10 ? "0" + minutes.toString() : minutes.toString();
        let secondsString = seconds < 10 ? "0" + seconds.toString() : seconds.toString();
        return hoursString + ":" + minutesString + ":" + secondsString;
    }

    function getTimeZone(time: string) {
        if (time === null) {
            return "No timezone";
        }
        let date = new Date(time);
        return date.toString().split(" ")[5];
    }

//format "2021-08-12T15:45:12.000+00:00" to "YYYY-MM-DD HH:MM:SS"
    function getStartTime(startTime: string) {
        if (startTime === null) {
            return "No start time";
        }
        let date = new Date(startTime);
        let dateString = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

        let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        let timeString = hours + ":" + minutes + ":" + seconds;

        return dateString + " " + timeString;
    }


    function downloadAudioFile(data: any) {
        // download file via api
        const axiosDataFetchRetry = getAxiosDataFetchRetryInstance();
        let objectName = data.storageObjectReference

        axiosDataFetchRetry.get(STORAGE_URL + "/" + objectName, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'audio/wav',
            }
        }).then(async (response) => {
            let blob = new Blob([response.data], {type: 'audio/wav'});
            let url = window.URL.createObjectURL(blob);

            // Create download element
            let a = document.createElement('a');
            a.href = url;
            a.download = objectName;
            a.click();
        }).catch((error) => {
            console.error(error);
        });
    }

    function pauseAudio(audioRef: any) {
        try {
            if (audioRef.current === null) {
                return;
            }
            audioRef.current.pause();
        } catch (e) {
            console.error(e + "!!!!!!");
        }
    }
}
