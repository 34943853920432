import {NavLink} from "react-router-dom";

// props: path(routing path), icon (All inside components SVG), name(Name displayed)
function NavItem(props: any) {
    return (
        <NavLink
            className={({isActive}) => (isActive ? "text-yellow-300 " : "text-zinc-200 hover:text-zinc-50 ")
                + "group flex flex-row gap-1 font-semibold"}
            to={"/" + props.path}>
            <props.icon
                className={"fill-zinc-200 group-[.text-yellow-300]:fill-yellow-300 h-[20px] w-[20px] mr-[1rem] self-center"}/>
            <p>{props.name}</p>
        </NavLink>
    );
}

export default NavItem;