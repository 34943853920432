import {Suspense, useEffect, useRef, useState} from "react";
import Spinner from "../../components/spinner";
import TableNav from "../../components/search/table/table-nav";
import SearchTableRow from "../../components/search/table/search-table-row";
import SearchFilterDropdown from "../../components/search/search-filter-dropdown";
import {Await} from "react-router-dom";
import {fetchFilterStatusDropdownData, fetchRecordingCount, fetchTableDataWithAllParams} from "../../utils/fetchData";

let fromUsername: string = "";
const limit = 15;
let offset = 0;

export default function CallsPage() {
    const [totalPages, setTotalPages] = useState<number>(0);
    const [data, setData] = useState<any[]>([]);
    const [statusDropdownValues, setStatusDropdownValues] = useState<any[]>([]);
    let promise;
    let dropdownStatus = "";


    useEffect(() => {
        dropdownStatus = "";
        //set user from localstorage
        let jwt = localStorage.getItem("access_token");
        if (jwt !== null) {
            let jwtData = jwt.split('.')[1]
            let decodedJwtJsonData = atob(jwtData)
            let decodedJwtData = JSON.parse(decodedJwtJsonData);
            fromUsername = decodedJwtData.sub;
        }
        promise = setNewData();
        setStatusDropdown();
    }, []);

    useEffect(() => {
        setPaginationTotalPages();
    }, [data]);

    const audioRef = useRef<HTMLAudioElement>(null);
    return (
        <main className="!pl-[240px] !pt-[60px] bg-slate-50 h-screen">
            <div
                className={"bg-white border rounded-lg shadow m-10 h-[90%] w-[95%] min-w-[600px] flex flex-col relative"}>
                <div className={"flex flex-row p-[24px] pb-0 justify-end"}>
                    <SearchFilterDropdown onOptionChange={handleStatusesDropdownChange}
                                          defaultOption={"All statuses"}
                                          options={statusDropdownValues}/>
                </div>
                <div className={"overflow-x-hidden overflow-y-auto flex flex-col"}>
                    <table className={"m-5 w-[calc(95%+300px)]"}>
                        <colgroup>
                            <col span={3}/>
                            <col span={1} className={"collapse w-[300px]"}/>
                        </colgroup>
                        <thead>
                        <tr className={"text-left border-b-4 h-10"}>
                            <th>Date time</th>
                            <th>Timezone</th>
                            <th>Duration</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Channel</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <Suspense fallback={<Spinner/>}>
                            <Await resolve={promise} errorElement={<h1> Something went wrong </h1>}>
                                <tbody className={"text-slate-500 w-[95%]"}>
                                {mapTableData(data, audioRef)}
                                </tbody>
                            </Await>
                        </Suspense>
                    </table>
                </div>
                <audio ref={audioRef} controls
                       className={"fixed left-[calc(50%-200px)] bottom-[5%] shadow-xl rounded-3xl w-[500px] animate-fade-in-up hidden"}>
                </audio>
                <div className={"flex flex-row self-end justify-end gap-10 pt-3 pb-8 bottom-0"}>
                    <TableNav totalPages={totalPages} currentPageChanged={changePage}/>
                </div>
            </div>
        </main>
    );

    function setPaginationTotalPages() {
        fetchRecordingCount(fromUsername, "", "", "", "").then(data => {
            if (data === undefined || data.data === undefined || data.data.recordingsCount === undefined) {
                return;
            }
            const pages = Math.ceil(data.data.recordingsCount / limit)
            setTotalPages(pages);
        });
    }

    function changePage(page: number) {
        offset = (page - 1) * limit;
        promise = setNewData();
    }

    function mapTableData(data: any, audioRef: any) {
        return data.map((data: any, index: number) => {
            return <SearchTableRow key={data?.storageObjectReference} data={data} audioRef={audioRef}/>
        })
    }

    async function setNewData() {
        const recordings = await fetchTableDataWithAllParams(limit, offset, fromUsername, "", dropdownStatus, "", "").catch(error => {
            console.log(error);
        });
        setData(recordings?.data?.recordings);
        return recordings;
    }

    async function setStatusDropdown() {
        let statuses: string[] = [];
        const status = await fetchFilterStatusDropdownData().catch(error => {
            console.log(error);
        });

        status?.data?.recordings.forEach((data: any) => {
            statuses.push(data.disposition);
        });
        setStatusDropdownValues(statuses);
    }

    function handleStatusesDropdownChange(event: any) {
        dropdownStatus = event?.target?.value;
        changePage(1)
    }
}
