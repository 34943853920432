function SearchFilterDropdown(props: any) {
    return (
        <select name="test" defaultValue={props.defaultOption} onChange={props.onOptionChange}
                className={"w-[142px] h-[30px] bg-slate-100 rounded text-center text-gray-500 text-opacity-95 text-base font-normal leading-normal focus:bg-slate-200 focus:text-gray-600 hover:bg-slate-200 hover:text-gray-600"}>
            <option value={props.defaultOption}>{props.defaultOption}</option>
            {
                props.options.map((option: any) => {
                    if (option !== props.defaultOption) {
                        return (<option key={option} value={option}>{option}</option>)
                    }
                })
            }
        </select>
    );
}

export default SearchFilterDropdown;