import SearchTableRow from "../../components/search/table/search-table-row";
import SearchFilterDropdown from "../../components/search/search-filter-dropdown";
import SearchBar from "../../components/search/search-bar";
import React, {Suspense, useEffect, useRef, useState} from "react";
import Spinner from "../../components/spinner";
import {
    fetchFilterCallsFromDropdownData,
    fetchFilterStatusDropdownData,
    fetchRecordingCount,
    fetchTableDataWithAllParams
} from "../../utils/fetchData";
import TableNav from "../../components/search/table/table-nav";
import {Await} from "react-router-dom";

let DropdownCallsFrom: string;
let DropdownStatuses: string;
let toCaller: string;
let transcriptText: string;
let limit: number;
let offset: number;
let searchingOn: string;

export default function SearchPage() {
    const [data, setData] = useState<any[]>([]);
    const [statusDropdownData, setStatusDropdownData] = useState<any[]>([]);
    const [callsFromDropdownData, setCallsFromDropdownData] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const audioRef = useRef<HTMLAudioElement>(null);
    let promise;

    // * Only gets called on startup and reload. NOT on data reload
    useEffect(() => {
        onStart();
    }, []);

    function onStart() {
        // * Reset all variables
        DropdownCallsFrom = "All calls from";
        DropdownStatuses = "All statuses";
        searchingOn = "";
        toCaller = "";
        transcriptText = "";
        limit = 15;
        offset = 0;
        setDropdownData(setStatusDropdownData, setCallsFromDropdownData)
        promise = setNewData();
    }

    function setPaginationTotalPages() {
        fetchRecordingCount(DropdownCallsFrom, toCaller, DropdownStatuses, transcriptText, "").then(data => {
            if (data === undefined || data.data === undefined || data.data.recordingsCount === undefined) {
                return;
            }
            const pages = Math.ceil(data.data.recordingsCount / limit)
            setTotalPages(pages);
        });
    }

    async function setNewData() {
        return await fetchTableDataWithAllParams(limit, offset, DropdownCallsFrom, toCaller, DropdownStatuses, transcriptText, "").then(data => {
            if (data === undefined || data.data === undefined || data.data.recordings === undefined) {
                setData([]);
                setTotalPages(0);
                return;
            }
            // noinspection JSUnresolvedReference
            setData(data.data.recordings);
            setPaginationTotalPages();
            constructSearchingOnString();
        });
    }

    function handleCallsFromDropdownChange(event: any) {
        DropdownCallsFrom = event.target.value;
        promise = setNewData();

    }

    function handleStatusesDropdownChange(event: any) {
        DropdownStatuses = event.target.value;
        promise = setNewData();
    }

    function callbackSetTableData(text: string, type: string) {
        const typeToFunctionMap: any = {
            "clid": () => {
                DropdownCallsFrom = text;
            },
            "to": () => {
                toCaller = text;
            },
            "text": () => {
                transcriptText = text;
            }
        };

        // Execute the function from the map
        if (typeToFunctionMap[type]) {
            typeToFunctionMap[type]();
            promise = setNewData();
        }
    }


    return (
        <main className="!pl-[240px] !pt-[60px] bg-slate-50 h-screen">
            <div
                className={"bg-white border rounded-lg shadow m-10 h-[90%] w-[95%] min-w-[600px] flex flex-col relative"}>
                <div className={"flex flex-row p-[24px] justify-between"}>
                    <SearchBar callback={callbackSetTableData}/>
                    <div className={"flex justify-around min-w-[350px] ml-10"}>
                        <SearchFilterDropdown onOptionChange={handleCallsFromDropdownChange}
                                              defaultOption={DropdownCallsFrom}
                                              options={callsFromDropdownData}/>
                        <SearchFilterDropdown onOptionChange={handleStatusesDropdownChange}
                                              defaultOption={DropdownStatuses}
                                              options={statusDropdownData}/>
                    </div>
                </div>
                <div className={"overflow-x-hidden overflow-y-auto flex flex-col"}>
                    <div className={"ml-6"}>
                        {displaySearchQuery()}
                    </div>
                    <table className={"m-5 w-[95%]"}>
                        <thead>
                        <tr className={"text-left border-b-4 h-10"}>
                            <th>Date time</th>
                            <th>Timezone</th>
                            <th>Duration</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Channel</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <Suspense fallback={<Spinner/>}>
                            <Await resolve={promise} errorElement={<h1> Something went wrong </h1>}>
                                <tbody className={"text-slate-500"}>
                                {mapTableData(data, audioRef)}
                                </tbody>
                            </Await>
                        </Suspense>
                    </table>
                </div>
                <audio ref={audioRef} controls preload={"metadata"} id={"empty"}
                       className={"fixed left-[calc(50%-200px)] bottom-[5%] shadow-xl rounded-3xl w-[500px] animate-fade-in-up z-50 hidden"}>
                    <track kind="captions" srcLang="en" label="english_captions"/>
                </audio>
                <div className={"flex flex-row self-end justify-end gap-10 pt-3 pb-8 bottom-0"}>
                    <TableNav totalPages={totalPages} currentPageChanged={changePage}/>
                </div>
            </div>
        </main>
    );

    function changePage(page: number) {
        offset = (page - 1) * limit;
        promise = setNewData();
    }

    function displaySearchQuery() {
        return searchingOn !== "" ? (
            <div className={"flex flex-row gap-3"}>
                <div className={"text-sm text-slate-500"}>
                    {searchingOn}
                </div>
                <button onClick={onStart} type={"button"}
                        className={"text-sm text-slate-500 underline hover:text-slate-700"}>Cancel
                </button>
            </div>
        ) : <div></div>;
    }
}

function mapTableData(data: any[], audioRef: any) {
    return data.map((data, index) => (
        <SearchTableRow key={data.toString() + index} data={data} audioRef={audioRef}/>
    ));
}

function setDropdownData(setStatusDropdownData: any, setCallsFromDropdownData: any) {
    fetchFilterStatusDropdownData().then(data => {
        let statuses: string[] = [];
        statuses.push(DropdownStatuses);

        if (data === undefined || data.data === undefined || data.data.recordings === undefined) {
            return;
        }

        //remove key from data
        // noinspection JSUnresolvedReference
        data.data.recordings.forEach((data: any) => {
            statuses.push(data.disposition);
        });
        setStatusDropdownData(statuses);
    });

    fetchFilterCallsFromDropdownData().then(data => {
        let callsFrom: string[] = [];
        callsFrom.push(DropdownCallsFrom);

        if (data === undefined || data.data === undefined || data.data.recordings === undefined) {
            return;
        }

        //remove key from data
        // noinspection JSUnresolvedReference
        data.data.recordings.forEach((data: any) => {
            callsFrom.push(data.clid);
        });
        setCallsFromDropdownData(callsFrom);
    });
}

function constructSearchingOnString() {
    searchingOn = "";

    if (DropdownCallsFrom !== "All calls from" || toCaller !== "" || DropdownStatuses !== "All statuses" || transcriptText !== "") {
        searchingOn += "Searching with the following";
    }

    if (DropdownCallsFrom !== "All calls from") {
        searchingOn += ", From: \"" + DropdownCallsFrom + "\"";
    }

    if (toCaller !== "") {
        searchingOn += ", To: \"" + toCaller + "\"";
    }

    if (DropdownStatuses !== "All statuses") {
        searchingOn += ", Status: \"" + DropdownStatuses + "\"";
    }

    if (transcriptText !== "") {
        searchingOn += ", Text: \"" + transcriptText + "\"";
    }
}
