import {ChangeEvent} from "react";
export function Input({label, id, type, placeholder, autoComplete, onChange}: Readonly<{
    label: string,
    id: string,
    type: string,
    placeholder?: string,
    autoComplete?: string,
    onChange?: Function
}>) {

    function onChangeEvent(e: ChangeEvent<HTMLInputElement>) {
        if (onChange) {
            onChange(e.target.value);
        }
    }

    return (
        <div className={"flex flex-col w-full gap-2"}>
            <label className={"flex justify-between font-semibold capitalize"} htmlFor={id}>{label}</label>
            <input className={"border rounded border-gray-300 p-1.5 mb-4"} type={type} id={id} name={id}
                   placeholder={placeholder} autoComplete={autoComplete}  onChange={onChangeEvent}/>
        </div>
    );
}