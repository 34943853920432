import SearchDialogItem from "./search-dialog-item";
import React, {useState} from "react";
import {fetchFromData, fetchTextData, fetchToData} from "../../utils/fetchData"

let searchText: string = "";
let searchError: string = "";
const limit: number = 3;

export default function SearchBar(props: any) {
    const [fromData, setFromData] = useState<any[]>([]);
    const [toData, setToData] = useState<any[]>([]);
    const [textData, setTextData] = useState<any[]>([]);

    return (
        <section className={"w-full relative"}>
            <input type="text"
                   onChange={(e) => searchChanged(e.target.value, setFromData, setToData, setTextData, e)}
                   className="bg-purple-white rounded border border-gray-400 p-2 pl-3 w-full hover:bg-slate-50 focus:outline-none focus:border-blue-500 peer focus:ring-1 focus:ring-blue-500 focus:ring-opacity-50 appearance-none"
                   placeholder="Search..."/>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
                 className={"absolute top-0 bottom-0 right-3 w-5 h-5 my-auto text-gray-400  pointer-events-none"}>
                <path
                    d="M18.4444 20L11.4444 13C10.8889 13.4444 10.25 13.7963 9.52778 14.0556C8.80556 14.3148 8.03704 14.4444 7.22222 14.4444C5.2037 14.4444 3.49556 13.7452 2.09778 12.3467C0.7 10.9481 0.000740741 9.24 0 7.22222C0 5.2037 0.699259 3.49556 2.09778 2.09778C3.4963 0.7 5.20444 0.000740741 7.22222 0C9.24074 0 10.9489 0.699259 12.3467 2.09778C13.7444 3.4963 14.4437 5.20444 14.4444 7.22222C14.4444 8.03704 14.3148 8.80556 14.0556 9.52778C13.7963 10.25 13.4444 10.8889 13 11.4444L20 18.4444L18.4444 20ZM7.22222 12.2222C8.61111 12.2222 9.79185 11.7359 10.7644 10.7633C11.737 9.79074 12.223 8.61037 12.2222 7.22222C12.2222 5.83333 11.7359 4.65259 10.7633 3.68C9.79074 2.70741 8.61037 2.22148 7.22222 2.22222C5.83333 2.22222 4.65259 2.70852 3.68 3.68111C2.70741 4.6537 2.22148 5.83407 2.22222 7.22222C2.22222 8.61111 2.70852 9.79185 3.68111 10.7644C4.6537 11.737 5.83407 12.223 7.22222 12.2222Z"
                    fill="#B1B8C8"/>
            </svg>
            {displaySearchError(searchError)}
            <div id={"search-dialog"}
                 className={"text-gray-600 absolute max-h-80 mt-2 w-full bg-white rounded-lg border border-gray-400 py-[10px] text-sm font-['Roboto'] invisible peer-focus:visible hover:visible hidden"}>
                <ul className={"flex flex-col space-y-0.5 max-h-24 overflow-x-hidden overflow-hidden"}>
                    <li className={"text-gray-500 text-opacity-95 text-base font-semibold px-[22px]"}>From
                        ({fromData.length})
                    </li>
                    {renderFromToData(fromData)}
                </ul>
                <ul className={"flex flex-col space-y-0.5 max-h-24 overflow-x-hidden overflow-hidden mt-2"}>
                    <li className={"text-gray-500 text-opacity-95 text-base font-semibold px-[22px]"}>To
                        ({toData.length})
                    </li>
                    {renderFromToData(toData)}
                </ul>
                <ul className={"flex flex-col space-y-0.5 max-h-24 overflow-x-hidden overflow-hidden mt-2"}>
                    <li className={"text-gray-500 text-opacity-95 text-base font-semibold px-[22px]"}>Text
                        ({textData.length})
                    </li>
                    {renderTextData(textData)}
                </ul>
            </div>
        </section>
    );

    function setTableDataCallback(event: any, clid: any, to: any) {
        if (clid) {
            props.callback(clid, "clid");
        } else if (to) {
            props.callback(to, "to");
        } else {
            props.callback(searchText, "text");
        }
    }

    function renderFromToData(data: any[]) {
        data = data.slice(0, 3);

        if (data.length <= 0) {
            return (
                <li className={"text-gray-500 text-opacity-95 text-base font-semibold px-[22px]"}>No results found</li>
            );
        } else {
            return data.map((data) => (
                <SearchDialogItem key={data.uniqueid} clid={data.clid} to={data.to} callback={setTableDataCallback}/>
            ));
        }
    }

    function renderTextData(data: any[]) {
        if (data.length <= 0) {
            return (
                <li className={"text-gray-500 text-opacity-95 text-base font-semibold px-[22px]"}>No results found</li>
            );
        }

        return (
            <button onClick={e => setTableDataCallback(e, null, null)}
                className={"cursor-pointer hover:bg-slate-50 px-[22px] py-[1px] text-left"}>
                "{searchText}" has been found in {data.length} recordings
            </button>
        );
    }
}

function displaySearchError(searchError: string) {
    return (
        <div className={"absolute flex flex-row"}>
            <p className={"text-red-500 text-opacity-95 mt-2 text-sm"}>{searchError}</p>
        </div>
    );
}

function hasInvalidCharacters(text: string) {
    return text.includes('"') || text.includes("'") || text.includes("\\");
}

function searchChanged(text: string, setFromData: any, setToData: any, setTextData: any, event: any) {
    const searchDialog = document.getElementById("search-dialog");
    event.target.classList.remove("border-red-500");
    event.target.classList.remove("focus:border-red-500");
    searchError = "";

    if (text.length <= 0 || hasInvalidCharacters(text)) {
        setFromData([]);
        setToData([]);
        setTextData([]);
        if (hasInvalidCharacters(text)) {
            event.target.classList.add("border-red-500");
            event.target.classList.add("focus:border-red-500");
            searchError = "Search text cannot contain the following characters: \"  '  \\";
        }
        searchDialog?.classList.add("hidden");
        return;
    }

    searchText = text;
    fetchFromData(limit, searchText).then(data => {
        // noinspection JSUnresolvedReference
        setFromData(data.data.recordings);
    }).catch(error => {
        console.log(error);
    });

    fetchToData(limit, searchText).then(data => {
        // noinspection JSUnresolvedReference
        setToData(data.data.recordings);
    }).catch(error => {
        console.log(error);
    });

    fetchTextData(searchText).then(data => {
        // noinspection JSUnresolvedReference
        setTextData(data.data.recordings);
    }).catch(error => {
        console.log(error);
    });

    searchDialog?.classList.remove("hidden");
}