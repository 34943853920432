import {LOGIN_URL, REFRESH_URL} from "../constants/Endpoints";
import axios from "axios";

export async function loginWithPasswordAndUsername(password: string, username: string) {
    return await axios.post(LOGIN_URL + "?username=" + username + "&password=" + password).then(response => {
        if (response.status === 200) {
            return response;
        }
    }).catch(error => {
        console.log(error);
    });
}

export async function refreshAccessToken() {
    localStorage.removeItem("access_token");
    const response: any = await axios.post(REFRESH_URL + "?refresh_token=" + localStorage.getItem("refresh_token")).catch(error => {
        logout();
    });

    if (!response) {
        logout();
        return;
    }

    if (response.status === 200) {
        const data: any = response.data;
        localStorage.setItem("access_token", data.access_token);
        localStorage.setItem("refresh_token", data.refresh_token);
    }
    return response;
}

function NavigateToLogin() {
    window.location.href = "/login";
}


export function logout(): void {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    NavigateToLogin();
}

