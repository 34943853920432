import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router, Outlet, Route, Routes, useNavigate} from "react-router-dom";
import './index.css';
import HelloWorldPage from './pages/helloWorldPage';
import reportWebVitals from './reportWebVitals';
import SearchPage from "./pages/search";
import LoginPage from "./pages/login";
import UploadPage from "./pages/upload";
import CallsPage from "./pages/calls";
import {BASE_URL} from "./constants/Endpoints";
import {getAxiosDataFetchRetryInstance} from "./utils/fetchData";
import Header from "./components/header";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


root.render(<App/>);

function App() {
    return (
        <Router>
            <React.StrictMode>
                <Routes>
                    <Route path="/" element={<HelloWorldPage/>}/>
                    <Route path={"/login"} id={"login"} element={<LoginPage/>}/>
                    <Route element={<Layout/>}>
                        <Route path={"/search"} element={<AuthProvider><SearchPage/></AuthProvider>}/>
                        <Route path={"/calls"} element={<AuthProvider><CallsPage/></AuthProvider>}/>
                        <Route path={"/retention"} element={<AuthProvider><div/></AuthProvider>}/>
                        <Route path={"/ruleengine"} element={<AuthProvider><div/></AuthProvider>}/>
                        <Route path={"/jobs"} element={<AuthProvider><div/></AuthProvider>}/>
                        <Route path={"/cisco"} element={<AuthProvider><div/></AuthProvider>}/>
                        <Route path={"/upload"} element={<AuthProvider><UploadPage/></AuthProvider>}/>
                    </Route>
                </Routes>
            </React.StrictMode>
        </Router>
    );
}

function Layout() {
    return (
        <div className="bg-slate-50 h-screen">
            <Header/>
            <Outlet/>
        </div>
    )
}

function AuthProvider({children}: { children: React.ReactNode }): React.ReactElement {
    let navigate = useNavigate();

    if (localStorage.getItem("access_token") === null && localStorage.getItem("refresh_token") === null) {
        navigate("/login", {replace: true})
        return <div/>;
    }

    getAxiosDataFetchRetryInstance().get(BASE_URL + "/").catch(error => {
        navigate("/login", {replace: true})
        return <div/>;
    });
    return (children) as React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();