import {ReactComponent as Logo} from '../../assets/logo-cybercloud-dark.svg';
import {Input} from "../../components/input";
import {useState} from "react";
import {loginWithPasswordAndUsername} from "../../utils/auth";
import {useNavigate} from "react-router-dom";

export default function LoginPage() {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string>("");
    return (
        <main className="bg-slate-50 h-screen">
            <section className={"h-full flex flex-row justify-center items-stretch"}>
                <form method={"post"} onSubmit={(data) => {
                    data.preventDefault();
                    Login(data, setErrorMessage, navigate);
                }}
                      className={"p-10 pb-60 w-[500px] flex flex-col justify-center items-center"}>
                    <Logo className={"max-w-[500px]"}/>
                    <Input label={"username"} id={"username"} type={"text"} placeholder={"Enter username..."}
                           autoComplete={"username"}/>
                    <Input label={"password"} id={"password"} type={"password"} placeholder={"Enter password..."}
                           autoComplete={"current-password"}/>
                    <div className={"text-red-500"}>{errorMessage}</div>
                    <button type="submit" className={"border rounded bg-cyan-600 w-full p-1.5 text-white mt-2"}>Login
                    </button>
                </form>
            </section>
        </main>
    );
}


function isPasswordValid(password: string) {
    return password.length > 0;
}

function isUsernameValid(username: string) {
    return username.length > 0;
}

function isValidForm(loginData: any, setErrorMessage: Function) {
    // + Username is [0] and password is [1]
    const username = loginData.target[0].value;
    const password = loginData.target[1].value;

    if (!isUsernameValid(username) && !isPasswordValid(password)) {
        setErrorMessage("Username and password cannot be empty");
        return false;
    }

    if (!isUsernameValid(username)) {
        setErrorMessage("Username cannot be empty");
        return false;
    }

    if (!isPasswordValid(password)) {
        setErrorMessage("Password cannot be empty");
        return false;
    }
    return true;
}

async function Login(loginData: any, setErrorMessage: Function, navigate: Function) {
    setErrorMessage("");

    if (!isValidForm(loginData, setErrorMessage)) {
        return;
    }

    const password: string = loginData.target[1].value;
    const username: string = loginData.target[0].value;

    const response: any = await loginWithPasswordAndUsername(password, username);

    if (!response?.status) {
        setErrorMessage("Something went wrong");
        return;
    }

    if (response.status === 401) {
        setErrorMessage("Invalid username or password");
        return;
    }

    if (response.status !== 200) {
        setErrorMessage("Something went wrong");
        return;
    }
    const data: any = response.data;

    localStorage.setItem("access_token", data.access_token);
    localStorage.setItem("refresh_token", data.refresh_token);
    navigate("/search")
    return false;
}
