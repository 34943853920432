export const BASE_URL = 'https://api.cybercloud.zensoftware.nl'

// * recordings
export const RECORDINGS_GRAPHQL_URL = `${BASE_URL}/graphql` // + POST for graphql query

// * storage
export const STORAGE_URL = `${BASE_URL}/storage`
// + /{sourceObjectName}/move/{destinationObjectName} -> POST
// + /{objectName} -> Upload(POST), download(GET) and DELETE
// + /{objectName}/exists ->  GET
export const STORAGE_METADATA_URL = `${BASE_URL}/storagemeta` // + GET

// * cisco
export const CISCO_INDEX_URL = `${BASE_URL}/cisco/index` // + GET
export const CISCO_DIRECTORY_SEARCH_URL = `${BASE_URL}/cisco/directorysearch` // + GET
export const CISCO_DIRECTORY_URL = `${BASE_URL}/cisco/directory` // + GET

// * caller id
export const CALLER_ID_URL = `${BASE_URL}/callerid` // + /dailednumber -> GET
export const CALLERS_URL = `${BASE_URL}/recordings/csv` // + GET

export const LOGIN_URL = `${BASE_URL}/login` // + POST
export const REFRESH_URL = `${BASE_URL}/auth/refresh` // + POST
