import {getAxiosDataFetchRetryInstance} from "./fetchData";
import {STORAGE_METADATA_URL, STORAGE_URL} from "../constants/Endpoints";


export async function uploadAudioFile(file: File): Promise<string> {
    const axiosRetryInstance = getAxiosDataFetchRetryInstance();
    const formData = new FormData();
    formData.append("file", file);
    const response = await axiosRetryInstance.post(STORAGE_URL + "/" + file.name, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    return response.data;
}

export async function uploadMetadata(mappedData: Map<string, string>, data: string[], filename: string, headersList: Map<string, string[]>): Promise<void> {
    const axiosRetryInstance = getAxiosDataFetchRetryInstance();
    // json object with keys start, clid, to , channel, disposition, duration and an array with the extraValues
    // check if startTime is a valid date
    // check if duration is a valid number
    if (!isDate(mappedData.get("startTime") as string)) {
        mappedData.delete("startTime");
    }

    if (!isNumber(mappedData.get("duration") as string)) {
        mappedData.delete("duration");
    }

    const metadata: any = {};
    mappedData.forEach((value, key) => {
        metadata[key] = value;
    });
    const extraValues: any = {}
    const headers: string[] = Array.from(headersList.keys());
    data.forEach((value, index) => {
            const key = headers[index];
        if (key !== undefined && value !== undefined && key !== "") {
            extraValues[key] = value;
        }
    });
    metadata["extraValues"] = extraValues;
    await axiosRetryInstance.post(STORAGE_METADATA_URL + "/" + filename, metadata);
}

function isDate(date: string): boolean {
    // check if date is a valid date format: 2023-11-09T16:11:02.000+00:00
    return /^\d{2}-\d{2}-\d{4}T\d{2}:\d{2}:\d{2}\.\d{3}\+\d{2}:\d{2}$/.test(date);
}

function isNumber(number: string): boolean {
    const isNumber = RegExp(/^\d+$/).exec(number);
    return isNumber !== null;
}
