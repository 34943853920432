import {RECORDINGS_GRAPHQL_URL} from "../constants/Endpoints";
import {refreshAccessToken} from "./auth";
import axios from "axios";

export function getAxiosDataFetchRetryInstance() {
    const axiosDataFetchRetry = axios.create()
    axiosDataFetchRetry.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("access_token");

    axiosDataFetchRetry.interceptors.response.use(
        response => {
            return response;
        }, async function (error) {
            const originalRequest: any = error.config;
            if (error.response.status === 403 && !originalRequest._retry) {
                originalRequest._retry = true;
                await refreshAccessToken();
                if (localStorage.getItem("access_token") === null) {
                    return Promise.reject(error);
                }

                originalRequest.headers.authorization = "Bearer " + localStorage.getItem("access_token");
                return axios(originalRequest).catch(() => {
                    console.error("Failed to retry original request")
                });
            }
            return Promise.reject(error);
        });

    return axiosDataFetchRetry;
}

export async function fetchData(query: string, url: string) {
    const axiosDataFetchRetry = getAxiosDataFetchRetryInstance();

    const response: any = await axiosDataFetchRetry.post(url, {
        query
    }, {
        headers: {
            "Content-Type": "application/json",
        }
    }).catch(error => {
        return [];
    })
    return response.data;
}

export async function fetchFilterCallsFromDropdownData() {
    return await fetchDistinctData("clid");
}

export async function fetchFilterStatusDropdownData() {
    return await fetchDistinctData("disposition");
}

export async function fetchDistinctData(distinctData: string) {
    const query = ` 
    query {
        recordings(
        limit: 20
        distinct_on: "${distinctData}"
        offset: 0
        ) {
            ${distinctData}
        }
    }
    `;
    return await fetchData(query, RECORDINGS_GRAPHQL_URL);
}

export async function fetchRecordingCount(from: string, to: string, status: string, searchString: string, distinct_on: string) {
    if (from === "All calls from") {
        from = "";
    }

    if (status === "All statuses") {
        status = "";
    }
    const query = `
    query {
        recordingsCount(
            from: "${from}"
            to: "${to}"
            status: "${status}"
            searchString: "${searchString}"
            distinct_on: "${distinct_on}"
        )
    }
    `
    return await fetchData(query, RECORDINGS_GRAPHQL_URL);
}

export async function fetchFromData(limit: number, fromCaller: string) {
    const query = ` 
    query {
        recordings(
        limit: ${limit}
        offset: 0
        distinct_on: "clid"
        from: "${fromCaller}"
            ) {
            uniqueid
            clid
        }
    }
    `;
    return await fetchData(query, RECORDINGS_GRAPHQL_URL);
}

export async function fetchToData(limit: number, toCaller: string) {
    const query = ` 
    query {
        recordings(
        limit: ${limit}
        offset: 0
        distinct_on: "to"
        to: "${toCaller}"
            ) {
            to
            uniqueid
        }
    }
    `;

    return await fetchData(query, RECORDINGS_GRAPHQL_URL);
}

export async function fetchTextData(searchString: string) {
    const query = ` 
    query {
        recordings(
        limit: 20
        offset: 0
        searchString: "${searchString}"
            ) {
            uniqueid
        }
    }
    `;
    return await fetchData(query, RECORDINGS_GRAPHQL_URL).catch(error => {
        return [];
    });
}


export async function fetchTableDataWithAllParams(limit: number, offset: number, from: string, to: string, status: string, searchString: string, distinct_on: string) {
    if (from === "All calls from") {
        from = "";
    }

    if (status === "All statuses") {
        status = "";
    }
    limit = changeUndefinedOrNullToEmptyStringOrZero(limit);
    offset = changeUndefinedOrNullToEmptyStringOrZero(offset);
    from = changeUndefinedOrNullToEmptyStringOrZero(from);
    to = changeUndefinedOrNullToEmptyStringOrZero(to);
    status = changeUndefinedOrNullToEmptyStringOrZero(status);
    searchString = changeUndefinedOrNullToEmptyStringOrZero(searchString);
    distinct_on = changeUndefinedOrNullToEmptyStringOrZero(distinct_on);

    // noinspection GraphQLUnresolvedReference
    const query = `#graphql
    query GetAllRecordings {
        recordings(
            limit: ${limit}
            offset: ${offset}
            from: "${from}"
            to: "${to}"
            status: "${status}"
            searchString: "${searchString}"
            distinct_on: "${distinct_on}"
        ) {
            start
            duration
            clid
            channel
            disposition
            uniqueid
            transcript
            to
            storageObjectReference
        }
    }
    `;

    return await fetchData(query, RECORDINGS_GRAPHQL_URL);
}

export function changeUndefinedOrNullToEmptyStringOrZero(value: any) {
    if (value === undefined || value === null || Number.isNaN(value) || value === "") {
        if (typeof value === "number") {
            return 0;
        }
        return "";
    }
    return value;
}