import {ChangeEvent} from "react";

export function Select({label, id, name, values, onChange, required = false, visibleOptions = 1, defaultValue = ""}: Readonly<{
    label: string,
    id: string,
    name: string,
    values: string[],
    onChange?: Function,
    required?: boolean
    visibleOptions?: number,
    defaultValue?: string
}>) {

    function onChangeEvent(e: ChangeEvent<HTMLSelectElement>) {
        if (onChange) {
            onChange(e.target.value);
        }
    }

    function renderOptions() {
        // add empty value in front and remove any other empty values
        values = values.filter(value => value !== "");
        values.unshift("");
        return values.map((value, index) => {
            return <option key={index} value={value}>{value}</option>
        })
    }

    return (
        <div className={"flex flex-col w-full gap-2"}>
            <label className={"flex justify-between font-semibold capitalize"} htmlFor={id}>{label}</label>
            <select className={"border rounded border-gray-300 p-1.5 mb-4"} defaultValue={defaultValue} id={id} name={name} size={visibleOptions}
                    onChange={onChangeEvent} required={required}>
                {renderOptions()}
            </select>
        </div>
    );
}