import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

function HelloWorldPage() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/login");
    }, []);

    return (
        <div className="text-center">
            <header
                className="h-[calc(100vh-60px)] flex flex-col items-center justify-center text-[calc(10px+5vmin)]">
                <p className="font-semibold">
                    Hello World!
                </p>
            </header>
        </div>
    );
}

export default HelloWorldPage;