
import React, {useEffect, useRef, useState} from "react";
import {ReactComponent as UploadIcon} from "../../assets/header/upload-icon.svg";
import {Status} from "../../constants/Status";
import {uploadAudioFile, uploadMetadata} from "../../utils/uploadFile";
import {MapColumnsDialog} from "../../components/upload/MapColumnsDialog";

export default function UploadPage() {
    // All files that are uploaded (audio and csv)
    const [files, setFiles] = useState<File[]>([]);
    // Status for stage of uploading files
    const [status, setStatus] = useState(Status.IDLE);
    // key: filename of uploaded audio file (No CSVs), value: Map<key: filename of csv file, value: row number in csv file>
    const [foundFiles, setFoundFiles] = useState<Map<string, Map<string, string>>>(new Map<string, Map<string, string>>());
    // key: header name, value: array of column numbers and file names
    const [headersList, setHeadersList] = useState<Map<string, string[]>>(new Map<string, string[]>());
    // key: filename, value: String of values (all fields in row where filename is found)
    const filesWithData = useRef<Map<string, string[]>>(new Map<string, string[]>());
    // key: database and application name, value: Map<key: csv filename, value: column number>
    const mappedColumns = useRef<Map<string, Map<string, number>>>(new Map<string, Map<string, number>>());
    // Error message
    const [error, setError] = useState<string>();

    const mapColumnsDialogRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        checkFilesInCsv();
        readCsvHeaders()
    }, [files]);

    useEffect(() => {
        if (checkValidFiles() && foundFiles.size > 0) {
            setStatus(Status.READY);
            setError(undefined);
        } else if (error === undefined) {
            setError("Please upload at least one audio file and only one csv file");
            setStatus(Status.IDLE);
        }
    }, [foundFiles, files]);

    return (
        <main className="!pl-[240px] !pt-[60px] bg-slate-50 h-screen">
            <div
                className={"bg-white border rounded-lg shadow m-10 h-[90%] w-[95%] min-w-[600px] flex flex-col relative"}>
                <div className="flex items-center justify-center w-100 m-9" onChange={handleFileUpload}>
                    <label htmlFor="dropzone"
                           className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-200
                                      border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <UploadIcon className={"fill-gray-400 hover:fill-gray-600 w-8 h-8 m-5"}/>
                            <p className="mb-2 text-sm text-gray-500"><span
                                className="font-semibold text-blue-500">Upload a file</span> or drag and drop</p>
                        </div>
                        <input id="dropzone" type="file" className={"invisible"} accept={"audio/wav, text/csv"}
                               multiple/>
                    </label>
                </div>
                <div className={"overflow-x-hidden overflow-y-auto"}>
                    <table className={"m-5 w-[95%] text-left"}>
                        <thead className={"border-b-4 h-10"}>
                        <tr>
                            <th>File name</th>
                            <th></th>
                            <th>Size</th>
                            <th>File Type</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderFileRow()}
                        </tbody>
                    </table>
                </div>
                <div className={"flex flex-row justify-end ml-auto gap-5 p-3 pb-5 bottom-0 mt-auto"}>
                    <p className={"text-red-500 font-medium"}>{files.length > 0 ? error : null}</p>
                    {renderButton()}
                </div>
            </div>
            <MapColumnsDialog mapColumnsDialogRef={mapColumnsDialogRef}
                              mappedColumns={mappedColumns}
                              headersList={headersList}
                              files={files}
                              uploadFilesCallback={uploadFiles}
            />
        </main>
    );

    function renderFileRow() {
        return files.map((file, index) => {
            const foundFilenames = Array.from(foundFiles.keys());
            return (
                <tr key={`${file.name}${index}`}
                    className={"border-b-2 h-10" + (foundFilenames.includes(file.name) ? " bg-neutral-50" : "")}>
                    <td>{file.name}</td>
                    <td className={"text-2xl font-bold text-center"}>{checkFile(file)}</td>
                    <td>{formatBytes(file.size)}</td>
                    <td>{file.type}</td>
                    <td>
                        <button
                            disabled={status === Status.UPLOADING}
                            className={"font-medium text-xl hover:font-bold px-3 py-1.5 text-center me-2 my-auto disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:font-medium"}
                            onClick={() => handleFileRemove(index)}>&#x2715;
                        </button>
                    </td>
                </tr>
            );
        });
    }

    function isIncludedInCsv(filename: string) {
        return foundFiles.has(filename);
    }

    function checkFile(file: File) {
        if (file.type === "audio/wav") {
            return isIncludedInCsv(file.name) ? <div>&#x2713;</div> : null;
        } else if (file.type === "text/csv" && files.filter(file => file.type === "text/csv").length !== 1) {
            return <div className={"text-red-500"}>&#x2717;</div>;
        }
    }

    function renderButton() {
        if (status === Status.READY) {
            return (
                <button type="button" onClick={showDialog}
                        className="w-48 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-bold rounded-lg text-sm px-5 py-2.5 me-2 mb-2">Ready
                    to upload</button>
            );
        } else if (status === Status.UPLOADING) {
            return (
                <button disabled type="button"
                        className="w-48 text-white bg-blue-700 font-bold rounded-lg cursor-default text-sm px-5 py-2.5 text-center me-2 inline-flex items-center">
                    <svg aria-hidden="true" className="inline w-4 h-4 me-3 text-white animate-spin"
                         viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"/>
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"/>
                    </svg>
                    Uploading...
                </button>
            );
        } else if (status === Status.IDLE) {
            return (
                <button disabled type="button"
                        className="w-48 cursor-default text-white bg-yellow-400 font-bold rounded-lg text-sm px-5 py-2.5 me-2 mb-2">Waiting
                    for files</button>
            );
        }
    }

    function showDialog() {
        mapColumnsDialogRef.current?.classList.remove("hidden");
    }

    function handleFileRemove(index: number) {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    }

    function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
        e.target.files && setFiles([...files, ...Array.from(e.target.files)]);
    }

    function formatBytes(bytes: number) {
        const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        let unitIndex = 0;

        while (bytes >= 1024 && unitIndex < units.length - 1) {
            bytes /= 1024;
            unitIndex++;
        }

        return `${bytes.toFixed(1)} ${units[unitIndex]}`;
    }

    function checkValidFiles() { // TODO add error messages
        if (files.length < 2) {
            return false;
        }

        const audioFiles = files.filter(file => file.type === "audio/wav");
        const csvFiles = files.filter(file => file.type === "text/csv");
        return !(audioFiles.length < 1 || csvFiles.length < 1 || csvFiles.length > 1);
    }

    function checkFilesInCsv() {
        const csvFile: File = files.filter(file => file.type === "text/csv")[0];
        if (csvFile) {
            const reader = new FileReader();
            reader.readAsText(csvFile);
            reader.onload = () => onCsvLoad(reader, csvFile);
            reader.onloadend = () => {
                reader.abort()
                return true;
            };
            reader.onerror = () => {
                reader.abort();
                return false;
            }
        }
    }

    function getAudioFileNames() {
        let fileNames: string[] = [];
        files.forEach(file => {
            if (file.type !== "text/csv") {
                fileNames.push(file.name);
            }
        });
        return fileNames;
    }

    function onCsvLoad(reader: FileReader, file: File) {
        const lines = reader.result?.toString().split("\n");
        const fileNamesSet = new Set(getAudioFileNames());
        const filenamesFound: Map<string, Map<string, string>> = new Map<string, Map<string, string>>();
        let row: number = 0;

        if (lines === undefined) {
            setError("Could not read csv file");
            return;
        }

        // search filename in csv
        for (const line of lines) {
            const fields = line.split(",");
            for (const field of fields) {
                if (fileNamesSet.has(field) && row) {
                    const filenameRowMap: Map<string, string> = filenamesFound.get(field) || new Map<string, string>();
                    filenameRowMap.set(file.name, row.toString());
                    filenamesFound.set(field, filenameRowMap);
                    fileNamesSet.delete(field);
                }
            }
            row++;
        }
        setFoundFiles(filenamesFound);
    }

    async function uploadFiles() {
        setStatus(Status.UPLOADING);
        await setFilesWithData();

        try {
            for (const file of files) {
                if (file.type === "audio/wav") {
                    //check if file is in csv
                    const metadata = filesWithData.current.get(file.name);
                    if (foundFiles.has(file.name) && metadata) {
                        // get the mapped columns data for this file
                        const mappedColumnsData = new Map<string, string>();
                        const csvFile = foundFiles.get(file.name)?.keys().next().value;
                        mappedColumns.current.forEach(async (columnMap, key) => {
                            if (csvFile) {
                                const value = await getMappedColumnValue(key, file.name, csvFile)
                                if (value) {
                                    mappedColumnsData.set(key, value);
                                }
                            }
                        });
                        const audioFile: any = await uploadAudioFile(file);
                        await uploadMetadata(mappedColumnsData, metadata, audioFile.storageObjectReference, headersList);
                    }
                }
            }
        } catch (e) {
            console.error("Error uploading files");
            setError("Something went wrong while trying to upload the files.")
            return;
        }

        setStatus(Status.IDLE);
        // remove processed files (Found Files) from files
        setFiles(files.filter(file => !foundFiles.has(file.name)));
        closeMappingDialog();
    }

    function closeMappingDialog() {
        mapColumnsDialogRef.current?.classList.add("hidden");
    }

    async function setFilesWithData() {
        filesWithData.current = new Map<string, string[]>();
        const filesToGetMetadataFor = Array.from(foundFiles.keys());
        for (const file of files) {
            if (file.type === "text/csv") {
                for (const filename of filesToGetMetadataFor) {
                    const row = foundFiles.get(filename)?.get(file.name);
                    if (row) {
                        const done = await setFileMetadata(file, filename, parseInt(row));
                        if (done) {
                            filesToGetMetadataFor.splice(filesToGetMetadataFor.indexOf(filename), 1);
                        } else {
                            console.error("failed to get metadata for " + filename);
                        }
                    }
                }
            }
        }
        return true;
    }

    function setFileMetadata(csv: File, filename: string, row: number) {
        if (row === undefined) {
            return;
        }
        let metadata: string[] = [];
        const reader = new FileReader();
        reader.readAsText(csv);
        return new Promise((resolve, reject) => {
            reader.onload = () => {
                const lines = reader.result?.toString().split("\n");
                for (const line of lines as string[]) {
                    // check for index of line to row
                    if (lines?.indexOf(line) === row) {
                        metadata = line.split(",");
                        break;
                    }
                }
            }
            reader.onloadend = () => {
                filesWithData.current.set(filename, metadata);
                resolve(true);
                reader.abort();
            }
            reader.onerror = () => {
                reject(reader.error);
                reader.abort();
            }
        });
    }

    async function getMappedColumnValue(uploadName: string, audioFilename: string, csvFilename: string) {
        if (mappedColumns.current.has(uploadName)) {
            const columnMap = mappedColumns.current.get(uploadName);
            if (columnMap?.has(csvFilename)) {
                const row = filesWithData.current.get(audioFilename);
                if (row) {
                    const columnNumber = columnMap.get(csvFilename);
                    if (columnNumber) {
                        const field = row[columnNumber - 1];
                        return field.replace(/\r/g, "");
                    }
                }
            }
        }
        return undefined;
    }


    function readCsvHeaders() {
        // array headerName -> column number + file name
        const headersInCsv = new Map<string, string[]>();
        files.forEach(file => {
            if (file.type === "text/csv") {
                const reader = new FileReader();
                reader.readAsText(file);
                reader.onload = () => onLoadSetHeaders(reader, file, headersInCsv)
                reader.onloadend = () => {
                    reader.abort()
                    setHeadersList(headersInCsv);
                };
            }
        });
    }

    function onLoadSetHeaders(reader: FileReader, file: File, headersInCsv: Map<string, string[]>) {
        const lines = reader.result?.toString().split("\n");
        const headersLine = lines?.shift()?.split(",");
        if (headersLine) {
            headersLine.forEach((header, index) => {
                header = header.replace(/\r/g, "");
                if (headersInCsv.has(header)) {
                    if (!headersInCsv.get(header)?.includes(file.name) && !headersInCsv.get(header)?.includes(index.toString())) {
                        headersInCsv.get(header)?.push(file.name, (++index).toString());
                    }
                } else {
                    headersInCsv.set(header, [file.name, (++index).toString()]);
                }
            });
        }
    }
}