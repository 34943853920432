import React, {useEffect} from "react";

export default function TableNav({totalPages, currentPageChanged}: Readonly<{
    totalPages: number,
    currentPageChanged: (currentPage: number) => void
}>) {
    const [currentPage, setCurrentPage] = React.useState(1);

    useEffect(() => {
        changePage(1)
    }, [totalPages]);

    function nextPage() {
        changePage(currentPage + 1)
    }

    function previousPage() {
        changePage(currentPage - 1)
    }

    function changePage(newPage: number) {
        if (newPage < 1 || newPage > totalPages || newPage === currentPage) {
            return;
        }
        setCurrentPage(newPage);
    }

    function loadPageNumbers() {
        if (totalPages <= 5 && totalPages > 0) {
            let pages: number[] = [];
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
            return pages;
        }

        if (totalPages > 5) {
            if (currentPage < 3) {
                return [1, 2, 3, "...", totalPages];
            }

            if (currentPage >= totalPages - 1) {
                return [1, "...", totalPages - 2, totalPages - 1, totalPages];
            }
            return [1, currentPage - 1, currentPage, currentPage + 1, totalPages];
        }
        return [1];
    }

    function renderPageNumbers() {
        return loadPageNumbers().map((number: any) => {
            return (
                <li key={number}>
                    <button onClick={() => changePage(number)}
                            className={"ml-auto py-0.5 px-2 border border-y " + (isCurrentPage(number) ? "bg-[#1FB8D3] text-white font-bold border-[#1FB8D3]" : "")}>
                        {number}
                    </button>
                </li>
            );
        });
    }

    useEffect(() => {
        currentPageChanged(currentPage);
    }, [currentPage]);

    function isCurrentPage(number: number) {
        return currentPage === number;
    }

    return (
        <nav className={"flex flex-row justify-end"}>
            <ul className={"flex flex-row m-5 border rounded-lg mr-10"}>
                <li>
                    <button onClick={previousPage}
                            className={"ml-auto py-0.5 px-2 border border-y rounded-l-lg font-mono font-bold"}>
                        {'<'}
                    </button>
                </li>
                {renderPageNumbers()}
                <li>
                    <button onClick={nextPage}
                            className={"ml-auto py-0.5 px-2 border border-y rounded-r-lg font-mono font-bold"}>
                        {'>'}
                    </button>
                </li>
            </ul>
        </nav>
    );
}

