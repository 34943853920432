import {ReactComponent as Logo} from '../../assets/logo-cybercloud-light.svg';
import NavItem from "./nav-item";
import {ReactComponent as SearchIcon} from '../../assets/header/search-icon.svg';
import {ReactComponent as CallsIcon} from '../../assets/header/calls-icon.svg';
import {ReactComponent as RetentionIcon} from '../../assets/header/retention-icon.svg';
import {ReactComponent as RuleEngineIcon} from '../../assets/header/rule-engine-icon.svg';
import {ReactComponent as JobsIcon} from '../../assets/header/jobs-icon.svg';
import {ReactComponent as CiscoPhonesIcon} from '../../assets/header/cisco-icon.svg';
import {ReactComponent as UploadIcon} from "../../assets/header/upload-icon.svg";

function Header() {
    return (
        <header>
            <aside className="fixed top-0 z-40 flex bg-gray-600 w-60 h-screen flex-shrink-0 flex-col">
                <div className="w-60 h-[84px] bg-slate-900 py-[12px]">
                    <Logo className={"object-cover"}/>
                </div>
                <nav className={"flex flex-col pl-[22px] pt-[31px] gap-[14px]"}>
                    <NavItem name={"Search"} icon={SearchIcon} path={"search"}/>
                    <NavItem name={"Calls"} icon={CallsIcon} path={"calls"}/>
                    <NavItem name={"Retention"} icon={RetentionIcon} path={"retention"}/>
                    <NavItem name={"Rule engine"} icon={RuleEngineIcon} path={"ruleengine"}/>
                    <NavItem name={"Jobs"} icon={JobsIcon} path={"jobs"}/>
                    <NavItem name={"Cisco phones"} icon={CiscoPhonesIcon} path={"cisco"}/>
                    <NavItem name={"Upload"} icon={UploadIcon} path={"upload"}/>
                </nav>
            </aside>
            <nav
                className="fixed top-0 z-40 flex w-[calc(100vw-240px)] flex-row px-4 bg-white h-[3.75rem]
                 ml-[240px] py-[10px]">
                <button>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="icon-park-outline:hamburger-button">
                            <g id="Group">
                                <path id="Vector" d="M6.625 9.95837H33.2917" stroke="#696F7C" strokeOpacity="0.945098"
                                      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path id="Vector_2" d="M6.625 19.9584H33.2917" stroke="#696F7C"
                                      strokeOpacity="0.945098" strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                                <path id="Vector_3" d="M6.625 29.9584H33.2917" stroke="#696F7C"
                                      strokeOpacity="0.945098" strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </g>
                        </g>
                    </svg>
                </button>
                <button className={"flex self-center ml-auto"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                        <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 10 9.23858 12 12 12Z" fill="#696F7C"/>
                        <path d="M12 14.5C6.99016 14.5 3 17.86 2.91016 22C2.91016 22.28 3.13016 22.5 3.41016 22.5H20.5902C20.8702 22.5 21.0902 22.28 21.0902 22C21.0902 17.86 17 14.5 12 14.5Z" fill="#696F7C"/>
                    </svg>
                </button>
            </nav>
        </header>
    );
}

export default Header;