import React, {useEffect, useRef} from "react";
import {Select} from "../select";
import Spinner from "../spinner";

export function MapColumnsDialog({
                                     mapColumnsDialogRef,
                                     headersList,
                                     files,
                                     mappedColumns,
                                     uploadFilesCallback
                                 }: Readonly<{
    mapColumnsDialogRef: React.MutableRefObject<HTMLDivElement | null>,
    headersList: Map<string, string[]>,
    files: File[],
    mappedColumns: React.MutableRefObject<Map<string, Map<string, number>>>,
    uploadFilesCallback: Function
}>) {
    const dispositionRef: React.MutableRefObject<string[]> = useRef<string[]>([]);
    const durationRef: React.MutableRefObject<string[]> = useRef<string[]>([]);
    const startTimeRef: React.MutableRefObject<string[]> = useRef<string[]>([]);
    const channelRef: React.MutableRefObject<string[]> = useRef<string[]>([]);
    const toRef: React.MutableRefObject<string[]> = useRef<string[]>([]);
    const clidRef: React.MutableRefObject<string[]> = useRef<string[]>([]);

    const uploadButtonRef = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        canUploadFiles();
    }, []);

    return (
        <div ref={mapColumnsDialogRef}
             className={"backdrop-blur-sm fixed z-40 md:inset-0 h-screen w-screen hidden"}>
            <div tabIndex={-1}
                 className={"overflow-x-hidden fixed right-0 mx-auto my-auto z-50 justify-center items-center shadow-lg md:inset-0 h-[90%] w-[80%]"}>
                <div className="p-4 bg-white rounded-lg shadow overflow-y-auto h-full">
                    <header className="flex items-center justify-between p-4 border-b border-gray-300 max-h-[10%]">
                        <h2 className="font-semibold text-gray-800">Map columns</h2>
                        <button
                            className="focus:outline-none text-gray-600 hover:text-gray-800"
                            aria-label="Close"
                            onClick={() => mapColumnsDialogRef.current?.classList.add("hidden")}
                        >
                            <svg className="w-4 h-4 fill-current" viewBox="0 0 16 16">
                                <path
                                    d="M11.95 4.879l-1.414-1.414L8 6.586 5.464 4.05 4.05 5.464 6.586 8l-2.536 2.536 1.414 1.414L8 9.414l2.536 2.536 1.414-1.414L9.414 8l2.536-2.536z"/>
                            </svg>
                        </button>
                    </header>
                    <main className={"p-4 flex flex-row h-[90%]"}>
                        <form className="p-4 md:p-5 max-w-sm flex flex-col mr-10 overflow-y-auto">
                            <p className={"mb-6 whitespace-pre-line"}>
                                Input the name of the column in the csv that contain the following information. <br/>
                                <span
                                    className={"text-sm text-neutral-500"}> For example: Title/First Name/Status </span>
                            </p>
                            <Select label={"Who made the call?"} id={"clid"} name={"clid"}
                                    values={Array.from(headersList.keys())} onChange={onClidChange}/>
                            <Select label={"Who was the call to?"} id={"to"} name={"to"}
                                    values={Array.from(headersList.keys())} onChange={onToChange}/>
                            <Select label={"Was the status of the call? (ANSWERED)"} id={"disposition"}
                                    name={"disposition"} values={Array.from(headersList.keys())}
                                    onChange={onDispositionChange}/>
                            <Select label={"What was the call duration? (in seconds)"} id={"duration"}
                                    name={"duration"} values={Array.from(headersList.keys())}
                                    onChange={onDurationChange}/>
                            <Select label={"When did the call begin? (date and time)"} id={"startTime"}
                                    name={"startTime"} values={Array.from(headersList.keys())}
                                    onChange={onStartTimeChange}/>
                            <Select label={"What was the channel of the call?"} id={"channel"} name={"channel"}
                                    values={Array.from(headersList.keys())} onChange={onChannelChange}/>
                        </form>
                        <div className={"p-4 flex flex-col w-full"}>
                            <p className={"text-lg font-medium border-b-2 max-w-[250px]"}>
                                All CSV headers:
                            </p>
                            <div className={"flex flex-col h-[100%]"}>
                                {renderHeaderList()}
                            </div>
                        </div>
                    </main>
                    <footer className={"flex flex-row justify-between"}>
                        <button ref={uploadButtonRef}
                                className={"bg-blue-500 enabled:hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50"}
                                onClick={uploadFiles}>Upload
                        </button>
                    </footer>
                </div>
            </div>
        </div>
    );

    async function uploadFiles() {
        await setMappedColumns();
        mapColumnsDialogRef.current?.classList.add("hidden");
        uploadFilesCallback();
    }

    function onDispositionChange(value: string) {
        onChangeMapDialogInput(dispositionRef, value);
    }

    function onDurationChange(value: string) {
        onChangeMapDialogInput(durationRef, value);
    }

    function onStartTimeChange(value: string) {
        onChangeMapDialogInput(startTimeRef, value);
    }

    function onChannelChange(value: string) {
        onChangeMapDialogInput(channelRef, value);
    }

    function onToChange(value: string) {
        onChangeMapDialogInput(toRef, value);
    }

    function onClidChange(value: string) {
        onChangeMapDialogInput(clidRef, value);
    }

    function onChangeMapDialogInput(ref: React.MutableRefObject<string[]>, value: string) {
        ref.current = value.split("/");
        canUploadFiles();
    }

    function canUploadFiles() {
        if (areAllMapDialogInputsFilledAndValid()) {
            enableUploadButton();
            return true;
        }
        disableUploadButton();
        return false;
    }

    function getColumnNumbersFromHeaders(headersList: Map<string, string[]>, headers: string[], csvFilename: string) {
        const columnNumbers: number[] = [];
        headers.forEach(header => {
            if (headersList.has(header)) {
                const headerInfo = headersList.get(header);
                if (headerInfo?.includes(csvFilename)) {
                    columnNumbers.push(parseInt(headerInfo[headerInfo.indexOf(csvFilename) + 1]));
                }
            }
        });
        return columnNumbers;
    }


    function setMappedColumnData(map: Map<string, Map<string, number>>, uploadName: string, headers: string[], csvFilename: string) {
        const columnNumbers = getColumnNumbersFromHeaders(headersList, headers, csvFilename);
        if (columnNumbers.length > 0) {
            if (!map.has(uploadName)) {
                map.set(uploadName, new Map<string, number>());
            }
            columnNumbers.forEach(columnNumber => {
                map.get(uploadName)?.set(csvFilename, columnNumber);
            });
        }
    }

    async function setMappedColumns() {
        const mappedColumnsMap = new Map<string, Map<string, number>>()
        // clid, disposition, duration, startTime, channel, to. Need to be mappable
        if (areAllMapDialogInputsFilledAndValid()) {
            for (const file of files) {
                if (file.type === "text/csv") {
                    setMappedColumnData(mappedColumnsMap, "clid", clidRef.current, file.name)
                    setMappedColumnData(mappedColumnsMap, "disposition", dispositionRef.current, file.name)
                    setMappedColumnData(mappedColumnsMap, "duration", durationRef.current, file.name)
                    setMappedColumnData(mappedColumnsMap, "start", startTimeRef.current, file.name)
                    setMappedColumnData(mappedColumnsMap, "channel", channelRef.current, file.name)
                    setMappedColumnData(mappedColumnsMap, "to", toRef.current, file.name)
                }
            }
            enableUploadButton();
            mappedColumns.current = mappedColumnsMap;
        } else {
            disableUploadButton();// TODO: (error handling) => Or Use dropdowns to select the right headers!
        }
    }

    function enableUploadButton() {
        uploadButtonRef.current?.removeAttribute("disabled");
    }

    function disableUploadButton() {
        uploadButtonRef.current?.setAttribute("disabled", "true");
    }

    function areAllMapDialogInputsFilledAndValid() {
        const allRefs: any = [dispositionRef, durationRef, startTimeRef, channelRef, toRef, clidRef];
        return allInputsFilled(allRefs) && allInputsValidHeaders(allRefs, headersList);
    }

    function allInputsFilled(inputs: React.MutableRefObject<string[]>[]): boolean {
        for (const input of inputs) {
            if (input.current.length === 0) {
                return false;
            }
        }
        return true;
    }

    function allInputsValidHeaders(inputs: React.MutableRefObject<string[]>[], headersList: Map<string, string[]>) {
        for (const input of inputs) {
            for (const header of input.current) {
                if (!headersList.has(header)) {
                    return false;
                }
            }
        }
        return true;
    }

    function renderHeaderList(): JSX.Element {
        return (
            <ul className={"overflow-y-auto"}>
                {
                    Array.from(headersList.keys()).map(header => {
                        if (header !== "") {
                            return (
                                <li key={header} className={"flex flex-row gap-4 items-center"}>
                                    <span className={"font-semibold"}>{header}</span>
                                    <span
                                        className={"text-sm text-neutral-500"}> {headersList.get(header)?.join(", ")}</span>
                                </li>
                            );
                        }
                        return null;
                    })
                }
            </ul>
        );
    }
}